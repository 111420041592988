/* Basic editor styles */
.ProseMirror > * + * {
  /* Spacing between words*/
  margin-top: 0.75em;
}

.ProseMirror {
  /* Makes text editor bigger TODO figure out how to make this take up all available height */
  min-height: 3rem;
}

.ProseMirror:focus {
  outline: none;
}

.ProseMirror ul,
.ProseMirror ol {
  padding: 0 1rem;
}

.ProseMirror h1,
.ProseMirror h2,
.ProseMirror h3,
.ProseMirror h4,
.ProseMirror h5,
.ProseMirror h6 {
  line-height: 1.1;
}

.ProseMirror code {
  background-color: rgba(97, 97, 97, 0.1);
  color: #616161;
}

.ProseMirror pre {
  background: #0d0d0d;
  border-radius: 0.5rem;
  color: #fff;
  font-family: "JetBrainsMono", monospace;
  padding: 0.75rem 1rem;
}

.ProseMirror pre code {
  background: none;
  color: inherit;
  font-size: 0.8rem;
  padding: 0;
}

.ProseMirror mark {
  background-color: #faf594;
}

.ProseMirror img {
  height: auto;
  max-width: 100%;
}

.ProseMirror hr {
  margin: 1rem 0;
}

.ProseMirror blockquote {
  border-left: 2px solid rgba(13, 13, 13, 0.1);
  padding-left: 1rem;
}

.ProseMirror hr {
  border: none;
  border-top: 2px solid rgba(13, 13, 13, 0.1);
  margin: 2rem 0;
}

.ProseMirror ul[data-type="taskList"] {
  list-style: none;
  padding: 0;
}

.ProseMirror ul[data-type="taskList"] li {
  align-items: center;
  display: flex;
}

.ProseMirror ul[data-type="taskList"] li > label {
  flex: 0 0 auto;
  margin-right: 0.5rem;
  user-select: none;
}

.ProseMirror ul[data-type="taskList"] li > div {
  flex: 1 1 auto;
}
